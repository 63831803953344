var defaultFancyboxConfig = {
	width: 600,
	height: 230,
	padding: 0,
	autoSize: true,
	autoHeight: true,
	autoWidth: true,
	fitToView: false,
	openMethod: 'dropIn',
	openSpeed: 250,
	closeMethod: 'dropOut',
	closeSpeed: 150,
	tpl: {
		wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>'
	}
};

$(document).ready(function ($) {

	// init close flash messages
	initCloseFM();

	// init Fancybox
	initFancybox();
});


// Fancybox
function initFancybox() {
	if ($.fn.fancybox) {

		// Standard fancybox
		$('.fancybox').fancybox({
			padding: 0
		});

		// Custom animations
		(function ($, F) {
			// Opening animation - fly from the top
			F.transitions.dropIn = function () {
				var endPos = F._getPosition(true);

				endPos.top = (parseInt(endPos.top, 10) - 500) + 'px';
				endPos.opacity = 0;

				F.wrap.css(endPos).show().animate({
					top: '+=500px',
					opacity: 1
				}, {
					duration: F.current.openSpeed,
					complete: F._afterZoomIn
				});
			};

			// Closing animation - fly to the top
			F.transitions.dropOut = function () {
				F.wrap.removeClass('fancybox-opened').animate({
					top: '-=500px',
					opacity: 0
				}, {
					duration: F.current.closeSpeed,
					complete: F._afterZoomOut
				});
			};

			// Next gallery item - fly from left side to the center
			F.transitions.slideIn = function () {
				var endPos = F._getPosition(true);

				endPos.left = (parseInt(endPos.left, 10) - 200) + 'px';
				endPos.opacity = 0;

				F.wrap.css(endPos).show().animate({
					left: '+=200px',
					opacity: 1
				}, {
					duration: F.current.nextSpeed,
					complete: F._afterZoomIn
				});
			};

			// Current gallery item - fly from center to the right
			F.transitions.slideOut = function () {
				F.wrap.removeClass('fancybox-opened').animate({
					left: '+=200px',
					opacity: 0
				}, {
					duration: F.current.prevSpeed,
					complete: function () {
						$(this).trigger('onReset').remove();
					}
				});
			};

		}(jQuery, jQuery.fancybox));

		// Modal box
		$('.modal-trigger').fancybox(defaultFancyboxConfig);

		// Configurable hijack popups
		$('a.configurable-hijack-trigger').each(function () {
			var active = $(this).attr('data-active');
			var hijackName = $(this).attr('data-hijack-name');
			var lastModification = $(this).attr('data-last-modification');
			var expireDays = parseInt($(this).attr('data-expire-days'));
			var delay = parseInt($(this).attr('data-delay')) * 1000;
			var expireSteps = parseInt($(this).attr('data-expire-steps'));
			var cookie = hijackName + lastModification;
			var selector = this;
			var blockCookieContent = parseInt($.cookie(cookie));
			var setBlockCookie = function (content) {
				if (expireDays > 0) {
					$.cookie(cookie, content, {path: '/', expires: expireDays});
				} else {
					$.cookie(cookie, content);
				}
			};

			if (active && expireSteps) {
				if (blockCookieContent > expireSteps) {
					blockCookieContent = 0;
				} else if (blockCookieContent) {
					blockCookieContent += 1;
				}
				setBlockCookie(blockCookieContent);
			}

			if ((active && !blockCookieContent) || window.location.search.indexOf(hijackName) >= 0) {
				setTimeout(function () {
					var fancyboxConfig = {};
					jQuery.extend(true, fancyboxConfig, defaultFancyboxConfig, {
						beforeClose: function () {
							setBlockCookie(1);
						}
					});
					$(selector).fancybox(fancyboxConfig).trigger('click');
				}, delay);
			}

			$('.fancybox-overlay, .modal-box a').on('click', function () {
				setBlockCookie(1);
				$.fancybox.close();
			});
		});
	}
}

// Close Flash message
function initCloseFM() {
	$('body').delegate('.flash-message .close', 'click', function () {
		$(this).closest('.flash-message').fadeOut();
		return false;
	});

	if ($('.flash-message').length) {
		setTimeout(function () {
			$('.flash-message' + '[data-hide="fade"]').fadeOut();
			$('.flash-message' + '[data-hide="slide"]').animate({'top': '-100'}, 200, function () {
				$('.flash-message' + '[data-hide="slide"]').hide();
			});
		}, 5000);
	}
}
