//OpenLayers
jQuery(function(){
	if($('.olmap').length) {
		var maps = $('.olmap').olMap();
	}
});


(function($) {
    $.fn.olMap = function() {
        return this.each(function(i) {
            var mapEl = $(this),
            	mapElId = 'olmap-' + i;
			var args = {
				zoom: 17,
				lat: mapEl.data('lat'),
				lng: mapEl.data('lng'),
				markerIcon: 'static/images/marker.png',
				centermarkerIcon: true, //set true if center of the marker should be centered to point
				grayScale: false,
				scrollWheel: false
			};
			mapEl.attr('id', mapElId);

			if(args.lat && args.lng) {
				//create open street map layer
				var osmLayer = new ol.layer.Tile({
					source: new ol.source.OSM()
				});

				//apply grayscale
				if(args.grayScale) {
					osmLayer.on('postcompose', function(event) {
						greyscale(event.context);
				   });
				}

				//create map
				var map = new ol.Map({
					target: mapElId,
					layers: [
					  osmLayer
					],
					view: new ol.View({
						center: ol.proj.fromLonLat([args.lng, args.lat]),
						zoom: args.zoom
					}),
					controls: ol.control.defaults({
						rotate: false,
						attribution: false
					}),
					interactions: ol.interaction.defaults({mouseWheelZoom: args.scrollWheel}),
				});

				//create marker and set marker icon
				var marker = new ol.Feature({
					geometry: new ol.geom.Point(
					  ol.proj.fromLonLat([args.lng, args.lat])
					),
				});

				if(args.markerIcon) {
					var markerStyle = {
						crossOrigin: 'anonymous',
						src: args.markerIcon
					};
					if(!args.centermarkerIcon) {
						markerStyle.anchor = [0.5, 1];
						markerStyle.anchorXUnits = 'fraction';
						markerStyle.anchorYUnits = 'fraction';
					}
					marker.setStyle(new ol.style.Style({
						image: new ol.style.Icon((markerStyle))
					}));
				}

				var vectorSource = new ol.source.Vector({
					features: [marker]
				});

				var markerVectorLayer = new ol.layer.Vector({
					source: vectorSource,
				});
				map.addLayer(markerVectorLayer);

				$(mapEl).on('resize', function() {
					setTimeout( function() {
						map.updateSize();
					}, 200);
				});
			}

        });
    };
}(jQuery));


function olMap() {

}

//grayscale to every pixel in canvas
function greyscale(context) {

	var canvas = context.canvas;
	var width = canvas.width;
	var height = canvas.height;

	var imageData = context.getImageData(0, 0, width, height);
	var data = imageData.data;

	for (i = 0; i < data.length; i += 4){
		var r = data[i];
		var g = data[i + 1];
		var b = data[i + 2];
		// CIE luminance for the RGB
		var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
		// Show white color instead of black color while loading new tiles:
		if(v === 0.0) v=255.0;  
		data[i+0] = v; // Red
		data[i+1] = v; // Green
		data[i+2] = v; // Blue
		data[i+3] = 255; // Alpha
	}
	context.putImageData(imageData,0,0);
}