jQuery(function(){
	$('.video').each(function () {
		var video = $(this).find('video').get(0),
			playButton = $(this).find('.video-play');
		if(typeof video != 'undefined' && video) {
			if(playButton.length) {
				playButton.on('click', function() {
					if (video.paused) {
						video.play();
						playButton.hide();
						video.setAttribute('controls','controls');
					}
				});
				video.addEventListener('pause', function(event) {
					playButton.show();
				});
			}
			else {
				video.setAttribute('controls','controls');
			}
			
		}
		
	});

	$('.video-iframe').each(function () {
		var videoIframe = $(this).find('iframe'),
			playButton = $(this).find('.video-play');
		if(videoIframe.length) {
			$(function() {
			    $(videoIframe).bind("load",function(){
			        var iframePlayButton =  $(this).contents().find(".ytp-gradient-top");
			        console.log(iframePlayButton.length);
			    });
			});
			
		}
		
	});
});
