 jQuery(function(){
	var body = $('body'),
		dropdownTriggers = $('.dropdown-trig, .subsite-nav-dropdown-trig'),
		searchTrigger = $('.search-trigger'),
		lngsTrigger = $('.lng-switch'),
		menuTrigger = $('.menu-trigger'),
		subDropdownTriggers = $('.subdropdown-trig'),
		searchInput = $('#search_form_query'),
		subsiteNav = $('.subsite-nav ul');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		hideDropdowns();
		body.toggleClass('menu-open');
	});

	$('.lng-switch, .search-trigger').on('click', function(e) {
		if($(window).width() < 992) {
			e.preventDefault();
			hideDropdowns();
			body.removeClass('menu-open');
		}
	});

	dropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('dropdown'),
			dropdown = $('#' + elId);
		if(dropdown.length) {
			trigger.on('click', function() {
				hideSubdropdowns();
				if(($(window).width() > 992) || trigger.hasClass('subsite-nav-dropdown-trig')) {
					var wasOpened = dropdown.hasClass('dropdown-open');
					$('.dropdown, .subsite-nav-dropdown').removeClass('dropdown-open').slideUp(100);
					$('.dropdown-trig, .subsite-nav-dropdown-trig').removeClass('dropdown-trig-active');
					$('.subsite-nav-dropdown-trig').closest('ul').removeClass('dropdown-wrap-active');
					if(!wasOpened) {
						dropdown.addClass('dropdown-open').slideDown(200, function() {
							if(trigger.hasClass('search-trigger') && searchInput.length) {
								searchInput.first().focus();
							}
						});
						trigger.addClass('dropdown-trig-active');
						trigger.closest('ul').addClass('dropdown-wrap-active');
					}
				}
				else {
					dropdown.toggleClass('dropdown-open').slideToggle(200);
					trigger.toggleClass('dropdown-trig-active');
				}
			});
		}
	});

	subDropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('dropdown'),
			subnav = $('#' + elId);
		if(subnav.length) {
			trigger.on('click', function() {
				if($(window).width() < 1080) {
				subnav.toggleClass('subdropdown-open').slideToggle(200);
				trigger.toggleClass('subdropdown-trig-active');
				}
			});
		}
	});

	$(document).bind('click', function (e) {
		if (!$('.main-nav, .header-btns, .x-layer').is(e.target) && $('.main-nav, .header-btns, .x-layer').has(e.target).length === 0) {
			hideDropdowns();
		}

		if (!$('.main-nav, .subsite-nav').is(e.target) && $('.main-nav, .subsite-nav').has(e.target).length === 0) {
			hideSubsiteNavDropdowns();
		}
	});

	function hideDropdowns() {
		hideSubdropdowns();

		$('.dropdown').slideUp(200, function() { $('.dropdown').removeClass('dropdown-open'); });
		$('.dropdown-trig').removeClass('dropdown-trig-active');
		setTimeout(function(){ body.removeClass('dropdown-open')}, 150);
	}

	function hideSubdropdowns() {
		if($(window).width() < 992) {
			$('.subdropdown').removeClass('subdropdown-open').slideUp(200);
			$('.subdropdown-trig').removeClass('subdropdown-trig-active');
		}
	}

	function hideSubsiteNavDropdowns() {
		hideSubdropdowns();

		$('.subsite-nav-dropdown').slideUp(200, function() { $('.subsite-nav-dropdown').removeClass('dropdown-open'); });
		$('.subsite-nav-dropdown-trig').removeClass('dropdown-trig-active');
		$('.subsite-nav-dropdown-trig').closest('ul').removeClass('dropdown-wrap-active');
		setTimeout(function(){ body.removeClass('dropdown-open')}, 150);
	}

	subsiteNav.on('scroll', function() {
		var subsiteNavParent = $(this).closest('.subsite-nav'),
			firstChild = $(this).find('li').first();
		if(subsiteNavParent.length && firstChild.length) {
			var left = firstChild.position().left,
				right = $(this).width() - $(window).width();
			if(left < 0) {
				subsiteNavParent.addClass('subsite-nav-scroll-left');
			}
			else {
				subsiteNavParent.removeClass('subsite-nav-scroll-left');
			}
		}
	});
 });