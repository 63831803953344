$(document).ready(function ($) {
	initCloseNotification();
});

// Close Notification
function initCloseNotification() {
	$('body').delegate('.notification .close', 'click', function () {
		$(this).closest('.notification').fadeOut();
		return false;
	});

	if ($('.notification').length) {
		setTimeout(function () {
			$('.notification' + '[data-hide="fade"]').fadeOut();
			$('.notification' + '[data-hide="slide"]').animate({'top': '-100'}, 200, function () {
				$('.notification' + '[data-hide="slide"]').hide();
			});
		}, 5000);
	}
}

// Set a notification cookie (for a day)
$('.notification-set-cookie').click(function() {
	var wrapper = $(this).closest('.notification');
	if (wrapper.length && wrapper.attr('id')) {
		$.cookie(wrapper.attr('id'), 'true', { path: '/', expires: 1 })
	}
});
// Show notification if no cookies are set
$('.notification').each(function() {
	var wrapper = $(this);
	if (wrapper.attr('id')) {
		if ($.cookie(wrapper.attr('id')) != 'true') {
			wrapper.show();
		}
	}
});